import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import Multiselect from "vue-multiselect";
import { IBranch } from "@/models/IBranch";
import BranchService from "@/services/BranchService";
import { Watch } from "vue-property-decorator";
import { IClientUser } from "@/models/IClientUser";
import ClientUserService from "@/services/ClientUserService";
import ClientPakcageService from "@/services/ClientPakcageService";
import { TheMask } from "vue-the-mask";
import moment from "moment";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Options({
  components: {
    AdminLayout,
    Multiselect,
    TheMask,
  },
})
export default class ClientUsers extends Vue {
  active = false;
  loading = false;
  isloaded = false;
  value = "";
  userId = 0;
  totalItems = 0;
  current_page = 1;
  search: string = "";
  bracnhes: IBranch[] = [];
  selectedBranch: any = {};
  selectedPackage: any = {};
  clientPackages: any = [];
  topbranchId = 0;
  sideBarTitle = "";
  isUpload = false;
  invalidList: any = [];
  users: any = [];
  selectedFile: any;
  user: IClientUser = {
    clientUserId: 0,
    userFullName: "",
    branchId: 0,
    clientId: 0,
    address: "",
    mobileNo: "",
    cnic: "",
    dateofBirth: "",
    joinDate: "",
    expiryDate: "",
    packageId: 0,
    isActive: false,
    userId: 0,
  };

  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 3,
    totalElements: 0,
  };

  async created() {
    var result = await ClientUserService.getListofClientUser(1, 1);

    if (result.items != undefined) {
      this.users = result.items;
      this.bracnhes = await BranchService.getAllBranch();
      this.totalItems = result.totalPages;
      this.isloaded = true;
    }
    else{
      this.bracnhes = await BranchService.getAllBranch();
      this.isloaded = true;
    }

    //this.bracnhes = await BranchService.getAllBranch(1);
    //this.isloaded = true;
    var user = JSON.parse(localStorage.getItem("user") || "{}");
    this.userId = user.userId;
    this.user.userId = this.userId;
  }

  handleChangePage(page) {
    this.table.page = page - 1;
  }

  async setTable(pagenumber: number) {
    this.users = [];
    let branchId = this.topbranchId == 0 ? this.getBranchId : this.topbranchId
    var result = await ClientUserService.getListofClientUser(
      branchId,
      pagenumber,
      this.search
    );
    this.users = result.items;
  }

  @Watch("users")
  onPropertyChanged(value: string, oldValue: string) {
    this.isloaded = true;
  }

  @Watch("value")
  onValuePropertyChanged(value: string, oldValue: string) {
    alert(value);
  }

  @Watch("current_page")
  async onPropertyCurrentPageChanged(value: string, oldValue: string) {
    //this.isloaded = false
    await this.setTable(this.current_page);
    //console.log(this.current_page)
  }

  @Auth.Getter
  private getBranchId!: number;

  ///Use for toggle side bar
  @Watch("collapseable", { deep: true })
  onBranchPropertyChanged(value: string, oldValue: string) {
    this.topbranchId = this.getBranchId;
  }

  async handleSearch(searching) {
    if (searching.length > 3) {
      this.search = searching;
      var result = await ClientUserService.getListofClientUser(
        this.topbranchId,
        1,
        this.search
      );
      this.users = result.items;
    } else if (searching.length == 0) {
      var result = await ClientUserService.getListofClientUser(
        this.topbranchId,
        1,
        ""
      );
      this.users = result.items;
    }
  }

  onBack() {
    this.active = !this.active;
    this.selectedBranch = {};
  }

  onUploadBack() {
    this.isUpload = !this.isUpload;
    this.active = !this.active;
    this.selectedBranch = {};
  }

  async onOpen() {
    this.active = !this.active;
    this.sideBarTitle = "Add New User";
    this.isUpload = false;
  }

  async onUpload() {
    this.active = !this.active;
    this.sideBarTitle = "Upload Bulk User";
    this.isUpload = true;
    this.invalidList = [];
  }

  openDoc()
  {
    const url = "../../documents/UserUploadTemplate.xlsx";
    window.location.href = url;
    //window.open("../../assets/documents/UserUploadTemplate.xlsx", "_blank")
  }

  async onEdit(userId: number) {
    this.user = await ClientUserService.getClientUserByUserId(userId);
    this.selectedBranch = this.bracnhes.find(
      (option) => option.branchId === this.user.branchId
    );
    await this.onChange(this.selectedBranch);
    this.selectedPackage = this.clientPackages.find(
      (x) => x.clientPackageId == this.user.packageId
    );
    this.active = !this.active;
  }

  async onChange(data: any) {
    if (data != undefined) {
      this.clientPackages = await ClientPakcageService.getClietnPackages(
        data.branchId
      );
    }

    console.log(data.branchId);
  }

  onSubmit() {
    (this as any).$Progress.start();
    this.loading = true;
    this.user.userId = this.userId;
    this.user.branchId = this.selectedBranch.branchId;
    console.log(this.selectedPackage);
    this.user.packageId = this.selectedPackage.clientPackageId;

    var dateofBirth = moment(this.user.dateofBirth, "DD/MM/YYYY");
    this.user.dateofBirth = dateofBirth.format("MM/DD/YYYY");

    var joinDate = moment(this.user.joinDate, "DD/MM/YYYY");
    this.user.joinDate = joinDate.format("MM/DD/YYYY");

    ClientUserService.saveClientUser(this.user).then(
      (data) => {
        this.loading = false;
        this.setTable(1);
        (this as any).$vs.notify({
          title: "New User",
          text: data.message,
          color: "success",
          icon: "check_circle",
        });
        (this as any).$Progress.finish();
        if (this.user.clientUserId == 0) {
          this.user = {
            clientUserId: 0,
            userFullName: "",
            branchId: 0,
            clientId: 0,
            address: "",
            mobileNo: "",
            cnic: "",
            dateofBirth: "",
            joinDate: "",
            expiryDate:"",
            packageId: 0,
            isActive: false,
            userId: 0,
          };
        }
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "New User",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }

  onFileChange(e) {
    const selectedFile = e.target.files[0]; // accessing file
    this.selectedFile = selectedFile;
  }

  OnSubmituploadFile() {
    let fd = new FormData();

    fd.append("file", this.selectedFile);
    fd.append("branchId", this.selectedBranch.branchId);
    this.loading = false;
    ClientUserService.uploadClientUser(fd).then(
      (data) => {
        if (data.message == "All data has been succsfully uploaded") {
          (this as any).$vs.notify({
            title: "New User",
            text: data.message,
            color: "success",
            icon: "check_circle",
          });
          this.setTable(1);
        this.loading = false;
        }
        if (data.result != null) {
          this.invalidList = data.result;
        }

      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "New User",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }
}
